<template>
     <MobileHeader  :mobileHeaderTitle="'EVENTS'" ></MobileHeader>
  <div class="container has-space has-header">
      <!-- <h1 class="event-title">{{$t('EVENTS')}}</h1> -->
    

      <div class="event-tabs__wrapper">
          <div class="display-flex event-tabs__container">
                <router-link :to="{ query: {type:o.queryString} }" class="tabs"  :class="{selected : o.selected}" v-for="(o, index) in tabs" :key="index">
                    <span class="tabs-name">{{$t(o.name)}}</span>
                    <div class="tabs-line"></div>
                </router-link> 
            </div>
      </div>

      <div class="event-body">
            <div v-if="!isNoData" class="event-list__wrapper">              
                <router-link  :to="{name: o.urlName, params:{contestId: o.contestId} }" class="event-list__card" v-for="(o, index) in eventList" :key="index">
                    <img class="hidden img-cover" :src="`${s3ImgUrl}${o.coverImage}`">
                    <div class="event-list__card-overlay"></div>
                    <div class="event-list__card-info">
                        <div v-if="o.participated" class="event-list__card-info__participated">{{$t('PARTICIPATED')}}</div>
                        <h2 class="mb-05rem event-list__card-info__title">{{o.contestTitle}}</h2>
                        <p class="event-list__card-info__desc">{{o.contestDescription}}</p>
                        <span class="event-list__card-info__time">{{o.displayDate}}</span>
                    </div>                   
                </router-link>
            </div> 
            <div v-else class="display-flex-center h-50vh">
                <span>{{$t(currentTabs.noDataName)}}</span>
            </div> 
      </div>

  </div>
</template>

<script>

let allTimers = [];
import MobileHeader from '@/components/mobile/Header.vue'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import moment from 'moment'

import config from '@/js/config.js'
export default {
	components:{  
		MobileHeader,    
    },
    data() {
        return {
            s3ImgUrl: config.s3ImgUrl,
            tabs: [
                {
                    name: 'ONGOING',
                    noDataName: 'NO_ONGOING_EVENT',
                    queryString: 'ongoing',
                    type: 2,
                    selected: true
                },
                {
                    name: 'UPCOMING',
                    noDataName: 'NO_UPCOMING_EVENT',
                    queryString: 'upcoming',
                    type: 3,
                    selected: false
                },
                {
                    name: 'PAST',
                    noDataName: 'NO_PAST_EVENT',
                    queryString: 'past',
                    type: 1,
                    selected: false
                },

            ],
            currentTabs: {
                noDataName: ''
            },

            isNoData: false,
            eventList: [],

            contestType: [
                {
                    type: 1,
                    urlName: 'basketballEventPrediction',
                },
                {
                    type: 2,
                    urlName: 'basketballEventTopreferral',
                },
                {
                    type: 3,
                    urlName: 'basketballEventMatchScore',
                },
                // {
                //     type: 4,
                //     urlName: 'basketballEventWorldCup',
                // },
                {
                    type: 5,
                    urlName: 'basketballEventTikTok',
                }
            ],

            // countryCode: null,

        }
    },
    mounted() {
        this.init();     
    },
    beforeUnmount() {
          allTimers.forEach(x => {
            clearInterval(x);
        });
    },
    computed: {
        ...mapGetters([              
            'isMobile',
            'currentLocale',
            'currentLanguageObj',
            'currentCountryObj',
            'isLogin',
            'userInfo',
            'userSwitchCountryLang',
        ]),
    },

    watch:{
        $route() {
            if (this.$route.name === 'basketballEvent') {
                 allTimers.forEach(x => {    
                    clearInterval(x);
                });
                this.init();
            }
        },
         isLogin: {
            deep: true,
            handler(prev, nesw) {
                this.init();
            }
        },
        userSwitchCountryLang: {
            handler(newVal, oldVal) {
                if (newVal && this.currentLanguageObj.displayName === "English") {
                    this.init();
                }
            }
        },
    },
    created() {
          
    },
    methods: {
        ...mapMutations([
            "SET_USER_COUNTRY_CODE",
        ]),
        ...mapActions([
                "getContentList",
                "getCountryCodeByIp",
                "currentCountryChangeEvent",
                "commonVerifyLogin",
            ]),

        init() {
            this.commonVerifyLogin();
            this.handleCurrentEventType();
        },
    
        handleCurrentEventType() {            
            let currentQueryString = this.$route.query.type;
            //default go to ongoing event
            if (typeof currentQueryString === 'undefined') {
                currentQueryString = "ongoing"
            }    
            this.tabs.forEach(x => {
                if (x.queryString === currentQueryString) {
                    x.selected = true;
                    this.currentTabs = x
                } else {
                    x.selected = false;
                }
            });
            this.getContentListData();
        },

        async getContentListData() {
            if(!this.isLogin && !this.userCountryCode) {
                let result = await this.getCountryCodeByIp();
				if (result.message === "Success") {
                    this.currentCountryChangeEvent(result.result.countryCode);
				}
            }

            let params = {
                timeType: this.currentTabs.type,
                language: this.currentLanguageObj.locale,
                lang: this.currentLanguageObj.apiParam,
                sportType: "BASKETBALL"
            }
          
            const result = await this.getContentList(params);
            this.eventList = result.result;
        
            if (this.eventList.length > 0) {
                this.isNoData = false;
                
                this.eventList.forEach(x => {            
                    for (let i = 0; i < this.contestType.length; i++) {
                        if (x.contestType === this.contestType[i].type) {
                            x.urlName = this.contestType[i].urlName;
                        }  
                    }              
                })
                this.updateEventListDate();
            } else {
                 this.isNoData = true;
            }

        },

        updateEventListDate() {

            allTimers.forEach(x => {
                clearInterval(x);
            });

            allTimers = [];
           
            for(let i = 0; i<this.eventList.length; i++) {
              
                 this.eventList[i].displayDate = "";

                 var t = new Date();
                 t.setSeconds(t.getSeconds() + 10);      

                 let endDate = this.eventList[i].endDate;
      

                 if (this.currentTabs.type === 3) {
                     //upcomming
                    endDate = this.eventList[i].startDate
                 } else if (this.currentTabs.type === 1) {
                     //past
                      this.eventList[i].displayDate = "";
                       continue;;  
                 }
        
                   let countDownDate = endDate;
                   
                     //clearInterval(interval);
                    
                    // Get today's date and time
                    let now = moment.utc().valueOf();

                    
                
                    // Find the distance between now and the count down date
                    var distance = countDownDate - now ;
       
                    if (distance < 0) {
                        this.eventList[i].displayDate = this.$i18n.t('EVENT_ENTRY_HAS_ENDED')
                      continue;
                    }
              
                        //Time calculations for days, hours, minutes and seconds
                        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                        
                        days = days < 10 ? '0' + days: days;
                        hours =  hours< 10 ? '0' + hours: hours;
                        minutes = minutes < 10 ? '0' + minutes: minutes;
                        seconds = seconds < 10 ? '0' + seconds: seconds;

                        let prefix = "";
                        if (this.currentTabs.type === 2) {
                            //ongoing
                            prefix = "ENDING_IN"
                        } else if (this.currentTabs.type === 3) {
                            //upcoming
                            prefix = "STARTING_IN"
                        }
            
                    let displayDay = `${days} ${this.$i18n.t('DAYS')}`;
                  
                    let displaySec = "";
                    if (days === "00") {
                        displayDay = "";
                        displaySec =`${seconds} ${this.$i18n.t('SECS')}` 
                    }

                    
            

                    this.eventList[i].displayDate = `${this.$i18n.t(prefix)} ${displayDay} ${hours} ${this.$i18n.t('HRS')} ${minutes} ${this.$i18n.t('MINS')} ${displaySec}`
                        // Update the count down every 1 second
                    

                        let interval = setInterval(() => {
                        
                                // Get today's date and time
                            
                                let now = moment.utc().valueOf();

                                // Find the distance between now and the count down date
                                var distance = countDownDate - now ;

                   
                                // Time calculations for days, hours, minutes and seconds
                                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                                
                                days = days < 10 ? '0' + days: days;
                                hours =  hours< 10 ? '0' + hours: hours;
                                minutes = minutes < 10 ? '0' + minutes: minutes;
                                seconds = seconds < 10 ? '0' + seconds: seconds;

                                let displayDay = `${days} ${this.$i18n.t('DAYS')}`;
                                let displaySec = "";
                                if (days === "00") {
                                    displayDay = "";
                                    displaySec =`${seconds} ${this.$i18n.t('SECS')}` 
                                }

                                // Display the result in the element with id="demo"
                                this.eventList[i].displayDate = `${this.$i18n.t(prefix)} ${displayDay} ${hours} ${this.$i18n.t('HRS')} ${minutes} ${this.$i18n.t('MINS')} ${displaySec}`
                        
                                // If the count down is finished, write some text
                                if (distance <= 1) {                                      
                                      if (this.currentTabs.type === 2) {
                                        //ongoing
                                        this.eventList[i].displayDate = this.$i18n.t('EVENT_ENTRY_HAS_ENDED')
                                    } else if (this.currentTabs.type === 3) {
                                        //upcoming
                                        this.init()
                                    }else if (this.currentTabs.type === 1) {
                                         this.eventList[i].displayDate = "";
                                    }
                                    clearInterval(interval);
                             
                                }
                        }, 1000);

                        allTimers.push(interval); 
                    
            }
         
        }
    },
}
</script>

<style>

.event-tabs__wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0 2.5rem 0;
}
.event-tabs__wrapper{
    white-space: nowrap;
    overflow-x: auto;
}
.event-body{
    min-height: 38rem;
}
.event-list__wrapper{
    display: grid;
    grid-template-columns: repeat(4,1fr);
	column-gap: 1rem;
    row-gap: 1rem;
    margin-bottom: 1rem;
}
.event-list__card{
    position: relative;
    border: .1rem solid var(--color-blue-20);
    border-radius: .5rem;
    height: 12.4rem;
    overflow: hidden;
    cursor: pointer;
}
.event-list__card-overlay{
    opacity: 0.6;
    background-color: black;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.event-list__card-info{
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 0;
    text-align: center;
    padding: .8rem;
    box-sizing: border-box;
}
.event-list__card-info__title{
    font-size: .95rem;
}
.event-list__card-info__desc{
     margin-bottom: 1.51rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8rem;
    color: #ABBCC4;
    min-height: 2rem;
}
.event-list__card-info__time{
    font-size: 0.6875rem;
    color: #ABBCC4;
}
.event-list__card-info__participated{
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background-color: #5CD66C;
    font-size: 0.6875rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0.25rem;
}
  @media (max-width: 768px){
      .event-tabs__wrapper{
          display: block;
          margin-bottom: 1.5rem;
      }
      .event-body{
          min-height: auto;
      }
      .event-title{
        text-align: center;
        font-size: 0.875rem;
        margin: 1rem;
      }
      .event-list__wrapper{
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
      }
      .event-list__card{
          height: 42vw;
      }
      .event-list__card-info__desc{
          margin-bottom: 1rem;
      }

  }

</style>